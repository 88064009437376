var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import MuiSnackbar from "@mui/material/Snackbar";
import { Alert } from "@docaposte-agility/da-design-system";
export default function Snackbar(props) {
    var _a = props.open, open = _a === void 0 ? false : _a, onClose = props.onClose, _b = props.message, message = _b === void 0 ? "" : _b, _c = props.severity, severity = _c === void 0 ? "success" : _c, _d = props.duration, duration = _d === void 0 ? 3000 : _d, _e = props.anchorOrigin, anchorOrigin = _e === void 0 ? undefined : _e;
    var handleClose = React.useCallback(function (event, reason) {
        if (reason === "clickaway") {
            return;
        }
        onClose(event, reason);
    }, [onClose]);
    return (_jsx(MuiSnackbar, { anchorOrigin: anchorOrigin, open: open, autoHideDuration: duration, message: _jsx(Alert, __assign({ onClose: handleClose, severity: severity }, { children: message }), void 0) }, void 0));
}
