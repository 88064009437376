var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Grid, Link, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ImageContainer from "cms/components/front-office/contents/ImageContainer";
import { getDateFromTimestamp } from "cms/utils/commonUtils";
import React from "react";
import { HashLink } from "react-router-hash-link";
import ZoomContext from "../../components/ZoomContext";
import { v4 as uuidv4 } from 'uuid';
var professorStyledCard = function (principal) { return ({
    display: "flex",
    flexDirection: "column",
    textDecoration: "none!important",
    height: "100%",
    width: "100%",
    bgcolor: "#fff",
    border: principal ? "2px solid" : "none",
    borderColor: "primary.main",
    boxShadow: "0px 4px 8px rgba(102, 109, 146, 0.08)",
    borderRadius: "8px",
    gap: "16px",
    p: 0.75,
    "&:hover": {
        border: "1px solid",
        borderColor: "primary.main"
    }
}); };
var studentStyledCard = function (principal) { return ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    textDecoration: "none!important",
    height: "100%",
    width: "100%",
    border: "1px solid #3B29AB",
    borderColor: "primary.main",
    borderRadius: "8px",
    gap: "16px",
    p: 0.75,
    "&:hover": {
        backgroundColor: "white",
        boxShadow: "4px 6px 0px #3B29AB",
        transform: "scale(1.001)"
    },
    "&::after": {
        content: '""',
        position: "absolute",
        top: "6px",
        left: "6px",
        width: "100%",
        height: "100%",
        backgroundColor: "transparent",
        borderRight: "1px solid #3B29AB",
        borderBottom: "1px solid #3B29AB",
        borderRadius: "8px"
    }
}); };
var imageParentStyled = {
    "& img": {
        borderRadius: "6px",
        overflow: "hidden",
        display: "block",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        objectPosition: "top center"
    }
};
export var tagStyled = {
    color: "secondary.main",
    border: "1px solid",
    borderColor: "secondary.main",
    borderRadius: "5px",
    mr: 1,
    fontSize: "0.75rem"
};
export var studentStyledTag = __assign(__assign({}, tagStyled), { position: "relative", "&::after": {
        content: '""',
        position: "absolute",
        top: "4px",
        left: "4px",
        width: "100%",
        height: "100%",
        backgroundColor: "transparent",
        borderRight: "1px solid #3B29AB",
        borderBottom: "1px solid #3B29AB",
        borderRadius: "5px"
    } });
export var ellipsisStyled = {
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box !important",
    WebkitBoxOrient: "vertical",
    whiteSpace: "normal",
    wordBreak: "break-word"
};
var titleStyled = function (principal) { return ({
    WebkitLineClamp: "2",
    fontSize: principal ? "1.875rem" : "1.125rem",
    lineHeight: principal ? "2.625rem" : "1.32rem",
    mt: principal ? 3 : 2.25,
    mb: principal ? 2 : 1.25
}); };
export var descriptionStyled = {
    WebkitLineClamp: { xs: "2", md: "3" },
    color: "greyColor.main"
};
var ArticleCard = function (props) {
    var zoom = React.useContext(ZoomContext).zoom;
    var page = props.page, _a = props.principal, principal = _a === void 0 ? false : _a;
    var _b = page || {}, id = _b.id, image = _b.image, title = _b.title, _c = _b.shortDescription, shortDescription = _c === void 0 ? "" : _c, _d = _b.created, created = _d === void 0 ? "" : _d, fullPath = _b.fullPath, tags = _b.tags;
    var theme = useTheme();
    var isMobile = !useMediaQuery(theme.breakpoints.up("md"));
    var date = getDateFromTimestamp(created, "DD MMM YYYY");
    return (_jsxs(Grid, __assign({ item: true, xs: 12, md: principal ? 5 : 4, sx: {
            display: "flex",
            maxHeight: principal ? 800 * zoom + "px" : "auto",
            mb: principal ? 0 : 6
        } }, { children: [_jsxs(Link, __assign({ component: HashLink, to: fullPath, sx: theme.professor ? professorStyledCard(principal) : studentStyledCard(principal), "aria-label": title }, { children: [image && (_jsx(Box, __assign({ sx: imageParentStyled }, { children: _jsx(ImageContainer, __assign({ ratio: principal ? (isMobile ? 52 : 74) : 56 }, { children: _jsx("img", { src: image.url, alt: image.alt, title: image.title }, void 0) }), void 0) }), void 0)), _jsxs(Box, __assign({ sx: { px: 2.5, pt: principal ? 2 : 1, pb: 3 } }, { children: [_jsxs(Box, __assign({ sx: { display: "flex", alignItems: "center" } }, { children: [tags &&
                                        tags.length > 0 &&
                                        tags.map(function (tag) { return (_jsx(Box, __assign({ component: "span", sx: __assign(__assign({}, tagStyled), { px: 1, py: 0.25 }) }, { children: tag }), uuidv4())); }), _jsx(Box, __assign({ sx: { color: "greyColor.main", fontSize: "0.75rem" } }, { children: date }), void 0)] }), void 0), title && (_jsx(Typography, __assign({ variant: isMobile ? "subtitle2" : "h3", sx: __assign(__assign({}, ellipsisStyled), titleStyled(principal)) }, { children: title }), void 0)), shortDescription && (_jsx(Typography, { variant: "textArticle", sx: __assign(__assign({}, ellipsisStyled), descriptionStyled), dangerouslySetInnerHTML: { __html: String(decodeURIComponent(shortDescription) || "") } }, void 0))] }), void 0)] }), void 0), principal && _jsx(Divider, { orientation: "vertical", sx: { display: { xs: "none", md: "block" }, ml: 1.5 } }, void 0)] }), id));
};
export default ArticleCard;
export var PrincipalArticleCard = function (props) { return _jsx(ArticleCard, __assign({}, props, { principal: true }), void 0); };
