var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import SiteContext from "cms/components/shared/SiteContext";
import ContactInformation from "./ContactInformation";
var ContactInformations = function (props) {
    var contactLink = props.contactLink;
    var site = useContext(SiteContext);
    var siteSettings = (site || {}).settings;
    var _a = siteSettings || {}, adress = _a.adress, city = _a.city, postalCode = _a.postalCode, mail = _a.mail, website = _a.website;
    return (_jsxs(Grid, __assign({ item: true, md: 4, lg: 3, container: true, direction: "column" }, { children: [_jsx(ContactInformation, { icon: "/Contact-map-marker.svg", title: "Trouvez-nous", content: _jsxs(_Fragment, { children: [_jsx("div", { children: adress }, void 0), _jsxs("div", { children: [postalCode, " ", city] }, void 0)] }, void 0), withDivider: true }, void 0), _jsx(ContactInformation, { icon: "/Contact-mail.svg", title: "Contactez-nous", content: mail, withDivider: true }, void 0), _jsx(ContactInformation, { icon: "/Contact-glob.svg", title: "Site web", content: website, withDivider: true }, void 0), _jsx(ContactInformation, { icon: "/Contact-admin-file.svg", title: "Administratif", 
                // TODO LINK
                content: _jsx(Typography, __assign({ variant: "text", sx: { "& a": { color: "inherit", fontStyle: "italic", textDecoration: "underline" } } }, { children: contactLink }), void 0) }, void 0)] }), void 0));
};
export default ContactInformations;
