import React, { useContext } from "react";
import Icon from "cms/components/front-office/contents/Icon";
import { Box } from "@mui/material";
import PageVersionContext from "cms/components/shared/PageVersionContext";
import SiteContext from "cms/components/shared/SiteContext";

const Social = () => {
  const { currentPageVersion } = useContext(PageVersionContext);
  const site = useContext(SiteContext);

  const { fullPath = "" } = currentPageVersion || {};

  const URL = `${site.host}${fullPath}`;

  const openWindow = (url, title) => {
    const WIDTH = 550;
    const HEIGHT = 285;
    const TOP = (window && window.innerHeight - HEIGHT) / 2;
    const LEFT = (window && window.innerWidth - WIDTH) / 2;
    window?.open(url, title, `height=${HEIGHT},width=${WIDTH},top=${TOP},left=${LEFT},resizable=1`);
  };

  const shares = [
    {
      icon: "facebook-f",
      onClick: () => openWindow(`https://www.facebook.com/sharer/sharer.php?u=${URL}`, "Facebook"),
      title: "Partager sur Facebook"
    },
    {
      icon: "twitter",
      onClick: () => openWindow(`https://twitter.com/intent/tweet?text=${URL}`, "Twitter"),
      title: "Partager sur Twitter"
    },
    {
      icon: "linkedin",
      onClick: () => openWindow(`https://www.linkedin.com/sharing/share-offsite/?url=${URL}`, "Linkedin"),
      title: "Partager sur LinkedIn"
    }
  ];

  return (
    <>
      {shares.map(share => {
        const { icon, onClick, title } = share;
        return (
          <Box key={icon} onClick={onClick} title={title}>
            <Icon
              icon={icon}
              type="fab"
              tabIndex={0}
              onKeyPress={event => {
                if (event.key === "Enter") {
                  onClick();
                }
              }}
            />
          </Box>
        );
      })}
    </>
  );
};

export default Social;
