import types from "cms/enums/contentsTypes.enum";
import AccordionPreview from "cms/components/back-office/contentsPreview/AccordionPreview";
import AccordionIcon from "cms/components/shared/icons/AccordionIcon";
export var accordion = {
    type: types.ACCORDION,
    label: "Accordéon",
    IconComponent: AccordionIcon,
    Preview: AccordionPreview,
    editionModalPosition: "top",
    value: "",
    dynamicChildKey: "panels",
    children: [
        {
            label: "Panels",
            key: "panels",
            type: types.ACCORDION_PANEL
        }
    ]
};
