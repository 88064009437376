var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import classnames from "classnames";
import MuiExpansionPanel from "@mui/material/Accordion";
import MuiExpansionPanelSummary from "@mui/material/AccordionSummary";
import MuiExpansionPanelDetails from "@mui/material/AccordionDetails";
import Icon from "cms/components/front-office/contents/Icon";
import Typography from "@mui/material/Typography";
export var componentClassName = "Da-Accordion";
var ExpansionPanel = function (props) {
    var _a = props.summary, summary = _a === void 0 ? "" : _a, _b = props.details, details = _b === void 0 ? null : _b, _c = props.className, className = _c === void 0 ? "" : _c, _d = props.expanded, expanded = _d === void 0 ? false : _d, index = props.index, onChange = props.onChange, others = __rest(props, ["summary", "details", "className", "expanded", "index", "onChange"]);
    var _e = useState(expanded), isExpanded = _e[0], setIsExpanded = _e[1];
    React.useEffect(function () {
        setIsExpanded(expanded);
    }, [expanded]);
    var handleIsExpanded = function (e) {
        if (typeof onChange === "function") {
            onChange(e, !isExpanded);
        }
        else {
            setIsExpanded(!isExpanded);
        }
    };
    return (_jsxs(MuiExpansionPanel, __assign({ square: true, onChange: handleIsExpanded, expanded: isExpanded, className: classnames(className, componentClassName) }, others, { children: [_jsx(MuiExpansionPanelSummary, __assign({ expandIcon: _jsx(Icon, { icon: "chevron-down", title: "Voir" }, void 0), "aria-controls": "panel" + index + "bh-content", id: "panel" + index + "bh-header" }, { children: _jsx(Typography, __assign({ sx: { color: "inherit", fontWeight: "600" } }, { children: summary }), void 0) }), void 0), _jsx(MuiExpansionPanelDetails, { children: details }, void 0)] }), void 0));
};
export default ExpansionPanel;
