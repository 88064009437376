var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from "@mui/system";
var dynamicPartStyled = {
    pt: { xs: 1.5, md: 4 }
};
var MainStyled = styled("main")(function (_a) {
    var theme = _a.theme;
    return ({
        position: "relative"
    });
});
var HomeTemplate = function (props) {
    var page = props.page;
    var contents = page.contents;
    var dynamicPart = contents.dynamicPart;
    return (_jsx(MainStyled, __assign({ sx: dynamicPartStyled }, { children: dynamicPart }), void 0));
};
export default HomeTemplate;
