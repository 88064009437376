import types from "cms/enums/contentsTypes.enum";
import ExpansionTextPreview from "cms/components/back-office/contentsPreview/ExpansionTextPreview";
export var expansionText = {
    label: "Texte dépliant",
    type: types.EXPANSION_TEXT,
    icon: "chevron-down",
    editionModal: true,
    editionModalPosition: "top",
    Preview: ExpansionTextPreview,
    children: [
        {
            label: "Libellé replié",
            key: "reducedLabel",
            type: types.INPUT,
            value: "Voir plus"
        },
        {
            label: "Libellé déplié",
            key: "expandedLabel",
            type: types.INPUT,
            value: "Voir moins"
        },
        {
            label: "Déplié par défaut",
            key: "defaultExpanded",
            type: types.CHECKBOX,
            value: "false"
        },
        {
            label: "text",
            key: "text",
            type: types.TEXT
        }
    ]
};
