var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import MuiLink from "@mui/material/Link";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
var ExpansionText = function (props) {
    var reducedLabel = props.reducedLabel, expandedLabel = props.expandedLabel, text = props.text, _a = props.defaultExpanded, defaultExpanded = _a === void 0 ? false : _a, others = __rest(props, ["reducedLabel", "expandedLabel", "text", "defaultExpanded"]);
    var _b = useState(defaultExpanded), isExpanded = _b[0], setIsExpanded = _b[1];
    var handleClickExpand = useCallback(function () {
        setIsExpanded(!isExpanded);
    }, [isExpanded]);
    return (_jsxs("div", __assign({}, others, { children: [isExpanded ? text : "", _jsxs(MuiLink, __assign({ component: "span", underline: "none", onClick: handleClickExpand, sx: { cursor: "pointer" } }, { children: [isExpanded ? expandedLabel : reducedLabel, isExpanded ? (_jsx(ExpandLess, { title: expandedLabel, component: "span", sx: { ml: 0.5, verticalAlign: "middle", fontSize: "1.2em" } }, void 0)) : (_jsx(ExpandMore, { title: reducedLabel, component: "span", sx: { ml: 0.5, verticalAlign: "middle", fontSize: "1.2em" } }, void 0))] }), void 0)] }), void 0));
};
export default ExpansionText;
