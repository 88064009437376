var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, createRef, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Block from "cms/components/shared/Block";
import Link from "cms/components/front-office/contents/Link";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import GlossaryItem from "./GlossaryItem";
var lettersStyled = function (lettersTop) { return ({
    display: { xs: lettersTop ? "flex" : "none", lg: "flex" },
    fontSize: { xs: "1.05rem", lg: "1.25rem" },
    fontWeight: { xs: 400, lg: 600 },
    position: { xs: "fixed", md: "-webkit-sticky", lg: "sticky" },
    flexDirection: { xs: "column", lg: "row" },
    alignItems: { xs: "center", lg: "stretch" },
    bgcolor: { xs: "primary.main", lg: "#fff" },
    top: { xs: lettersTop ? lettersTop : "initial", lg: 0 },
    right: 0,
    mb: { lg: 1 },
    py: { xs: 1, lg: 2 }
}); };
var letterLinkStyled = {
    textTransform: "uppercase",
    flex: 1,
    maxWidth: "50px",
    cursor: "pointer",
    lineHeight: { md: "1.75rem" },
    px: { xs: 0.5, lg: 0 },
    py: { xs: 0.25, lg: 0 },
    "&:hover": {
        color: "secondary.main"
    }
};
var letterTitleStyled = {
    color: "primary.dark",
    pb: 2,
    borderBottom: "1px solid",
    borderColor: "secondary.main",
    "& h2": {
        m: 0,
        px: { xs: 2, lg: 0 }
    }
};
var Glossary = function (props) {
    var location = props.location;
    var _a = props.items, items = _a === void 0 ? [] : _a;
    var _b = useState(null), lettersTop = _b[0], setLettersTop = _b[1];
    var _c = useState([]), letters = _c[0], setLetters = _c[1];
    var hash = location.hash;
    if (items && !Array.isArray(items)) {
        items = [items];
    }
    var filteredItems = items.filter(function (item) { return item && item.word; });
    filteredItems.sort(function (a, b) { return a.word.localeCompare(b.word); });
    var groupedItems = {};
    filteredItems.forEach(function (item) {
        var word = item.word;
        var firstLetter = word.toUpperCase().split("")[0];
        groupedItems[firstLetter] = groupedItems[firstLetter] || [];
        groupedItems[firstLetter].push(item);
    });
    var lettersRef = createRef();
    var theme = useTheme();
    var isMobile = !useMediaQuery(theme.breakpoints.up("md"));
    useEffect(function () {
        if (isMobile) {
            setLettersTop((window.innerHeight - lettersRef.current.getBoundingClientRect().height) / 2);
        }
    }, [lettersRef, isMobile]);
    useEffect(function () {
        // Si trop de lettres, alors on remplace l'excedent par "..." au milieu de la liste
        // qui pointera vers la première lettre retirée
        var allLetters = Object.keys(groupedItems).map(function (item) { return ({ letter: item, hash: item }); });
        if (isMobile) {
            var maxLetters = 15;
            var nbLetters = Object.keys(groupedItems).length;
            if (nbLetters > maxLetters) {
                var nbSubstract = nbLetters - maxLetters;
                var indexStartSubstract = Math.round(maxLetters / 2);
                allLetters.splice(indexStartSubstract, nbSubstract, {
                    letter: "...",
                    hash: allLetters[indexStartSubstract].hash
                });
            }
        }
        setLetters(allLetters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, isMobile]);
    return (_jsxs(Fragment, { children: [_jsx(Box, __assign({ sx: lettersStyled(lettersTop), ref: lettersRef }, { children: letters.map(function (item) { return (_jsx(Link, __assign({ smooth: true, url: location.pathname + "#" + item.hash, sx: __assign(__assign({}, letterLinkStyled), { color: { xs: "common.white", lg: hash === "#" + item.hash ? "secondary.dark" : "secondary.main" }, fontSize: { md: hash === "#" + item.hash && "1.75rem" } }) }, { children: item.letter }), item.letter)); }) }), void 0), _jsx(Box, __assign({ sx: { pt: { xs: 1, lg: 0 } } }, { children: Object.keys(groupedItems).map(function (letter) { return (_jsxs(Block, __assign({ sx: { pt: { xs: 1, lg: 0 } }, id: letter }, { children: [_jsx(Box, __assign({ sx: letterTitleStyled }, { children: _jsx(Typography, __assign({ variant: "h2" }, { children: letter }), void 0) }), void 0), groupedItems[letter].map(function (item) { return (_jsx(GlossaryItem, __assign({}, item), item.id)); })] }), letter)); }) }), void 0)] }, void 0));
};
export default withRouter(Glossary);
