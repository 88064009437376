import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import generateTemplatePropsFromContents from "cms/utils/templatePropsUtils";
import Link from "cms/components/front-office/contents/Link";
import Icon from "cms/components/front-office/contents/Icon";
import { useFooter } from "cms/services/SectionService";
import SiteContext from "cms/components/shared/SiteContext";
import Social from "components/Social";
import ContactBlock from "components/Footer/ContactBlock";
import SubtitleBlock from "components/Footer/SubtitleBlock";
import { styled } from "@mui/system";

const FooterStyled = styled("footer")(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.primary.main,
  marginTop: 100,
  display: "flex",
  paddingTop: 24,
  paddingBottom: 24,
  [theme.breakpoints.down("xs")]: {
    paddingLeft: 16,
    paddingRight: 16
  },
  [theme.breakpoints.down("lg")]: {
    paddingLeft: 0,
    paddingRight: 0
  }
}));

const logoStyled = {
  "& img": {
    height: "100%",
    width: "220px"
  }
};

const socialsStyled = {
  display: "flex",
  flexDirection: "row",
  color: "#fff",
  my: 2,
  "& a": {
    mr: 2.5
  },

  "& i": {
    color: "primary.light",
    fontSize: 20
  }
};

const bodyStyled = {
  color: "primary.light",
  fontFamily: '"Roboto"',
  letterSpacing: "0.1em",
  fontSize: { xs: "0.7rem", lg: "0.85rem" }
};

const dividerStyled = {
  display: { xs: "block", md: "none" },
  mx: -4,
  mb: 2.5,
  bgcolor: "primary.light",
  opacity: "0.4"
};

const Footer = () => {
  const site = useContext(SiteContext);
  const { footer } = useFooter(site.id);

  const footerContents = (footer && generateTemplatePropsFromContents(footer.contents)) || {};
  const linkInternals = Object.keys(footerContents);
  const { settings: siteSettings } = site || {};
  let { socialNetworks = [] } = siteSettings || {};
  const { copyright = "", logoFooter, adress, postalCode, city, iconAdress, mail, iconMail } = siteSettings || {};
  if (socialNetworks && !Array.isArray(socialNetworks)) {
    socialNetworks = [socialNetworks];
  }

  return (
    <FooterStyled>
      <Container maxWidth="lg">
        <Grid container justifyContent="space-between">
          <Grid lg={2} item sx={{ display: { xs: "none", lg: "block" } }}>
            <Box sx={logoStyled}>
              {logoFooter ? (
                logoFooter
              ) : (
                <Typography sx={{ color: "primary.light", textAlign: "justify" }}>
                  Le service M.I.A. Seconde est impulsé par le ministère chargé de l’Éducation nationale et financé par
                  le Secrétariat général pour l’investissement.
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} lg={9} sx={{ mr: { xs: 4.5, lg: 0 } }}>
            <SubtitleBlock subtitle="Plan du site" />
            {(linkInternals.length > 0 &&
              linkInternals.map(linkInternal => (
                <Grid container columnSpacing={6} key={linkInternal}>
                  {(Array.isArray(footerContents[linkInternal])
                    ? footerContents[linkInternal]
                    : [footerContents[linkInternal]]
                  ).map(list1item => (
                    <Grid
                      item
                      key={list1item.id}
                      sx={{
                        mb: 3,
                        "& > a": { fontSize: "1rem", color: "primary.light", fontFamily: '"Roboto"' }
                      }}
                    >
                      <Link {...list1item.link} key={list1item.id}>
                        {list1item.title}{" "}
                        {list1item && list1item.link && list1item.link.external && (
                          <Icon icon="external-link" title="Nouvel onglet" />
                        )}
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              ))) ||
              null}
            <Divider sx={dividerStyled} />
            <SubtitleBlock subtitle="Contact" />
            <Grid container columnSpacing={2}>
              {adress && <ContactBlock icon={iconAdress} contact={`${adress} ${postalCode} ${city}`} />}
              {adress && <ContactBlock icon={iconMail} contact={mail} />}
            </Grid>
            <Box sx={socialsStyled}>
              <Social socialNetworks={socialNetworks} />
            </Box>
            <Divider sx={dividerStyled} />
            {copyright && (
              <Box sx={socialsStyled}>
                <Typography sx={bodyStyled}>© {copyright}</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </FooterStyled>
  );
};

export default Footer;
