import types from "cms/enums/contentsTypes.enum";
import ExpansionPanelPreview from "cms/components/back-office/contentsPreview/ExpansionPanelPreview";
import ExpansionPanelIcon from "cms/components/shared/icons/ExpansionPanelIcon";
export var expansionPanel = {
    label: "Panneau dépliant",
    type: types.EXPANSION_PANEL,
    IconComponent: ExpansionPanelIcon,
    Preview: ExpansionPanelPreview,
    editionModal: true,
    editionModalPosition: "top",
    requiredByKey: ["summary"],
    children: [
        {
            label: "Titre du paneau",
            key: "summary",
            type: types.INPUT,
            value: "Titre"
        },
        {
            label: "details",
            key: "details",
            type: types.EXPANSION_PANEL_DETAILS
        }
    ]
};
