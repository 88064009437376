import types from "cms/enums/contentsTypes.enum";
import AttachmentPreview from "cms/components/back-office/contentsPreview/AttachmentPreview";
export var attachment = {
    label: "Pièce jointe",
    type: types.ATTACHMENT,
    icon: "paperclip",
    editionModal: true,
    editionModalPosition: "top",
    Preview: AttachmentPreview,
    requiredByKey: ["file"],
    children: [
        {
            label: "Choisir un document",
            key: "file",
            type: types.FILE
        },
        {
            label: "Titre",
            key: "title",
            type: types.INPUT,
            value: "Pièce jointe"
        },
        {
            label: "Description",
            key: "description",
            type: types.INPUT,
            value: ""
        },
        {
            label: "Texte du lien",
            key: "linkText",
            type: types.INPUT,
            value: "Télécharger le fichier"
        }
    ]
};
