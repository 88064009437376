var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ImageContainer from "cms/components/front-office/contents/ImageContainer";
import { Divider, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ZoomContext from "../ZoomContext";
var CardBox2 = function (props) {
    var zoom = useContext(ZoomContext).zoom;
    var id = props.id, title = props.title, description = props.description, image = props.image, position = props.position, others = __rest(props, ["id", "title", "description", "image", "position"]);
    var theme = useTheme();
    var isMobile = !useMediaQuery(theme.breakpoints.up("md"));
    var styleCard = {
        bgcolor: "white",
        px: { xs: 2, md: 4 },
        py: { xs: 2.5, md: 3.5 },
        borderRadius: "6px",
        height: { xs: "auto", md: "310px", lg: 296 * zoom + "px" },
        display: "flex",
        flexDirection: { xs: "row", md: "column" },
        alignItems: "center",
        flexGrow: "1",
        boxShadow: "0px 4px 8px rgba(102, 109, 146, 0.08)"
    };
    styleCard.flexDirection.xs = position;
    return (_createElement(Box, __assign({}, others, { key: id, sx: styleCard }),
        image && (_jsx(Box, __assign({ sx: {
                width: { xs: "250px", sm: "150px", md: "75%" },
                "& img": { objectFit: "contain" }
            } }, { children: _jsx(ImageContainer, __assign({ ratio: isMobile ? 100 : 40 }, { children: image }), void 0) }), void 0)),
        _jsx(Divider, { sx: { my: { md: 2 }, mx: { xs: 2, md: 0 } }, orientation: isMobile ? "vertical" : "horizontal", variant: "middle", flexItem: true }, void 0),
        _jsxs(Box, { children: [title && (_jsx(Typography, __assign({ variant: "h5", component: "div", sx: { mb: 1.25, fontSize: { xs: "1.2rem", md: "1.5rem" } } }, { children: title }), void 0)), description && (_jsx(Typography, __assign({ variant: "subtitle2", component: "div", sx: { mb: 1.25, lineHeight: "16px", letterSpacing: "5%", fontWeight: "500" } }, { children: description }), void 0))] }, void 0)));
};
export default CardBox2;
