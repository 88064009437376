import React, { useContext } from "react";
import SiteContext from "cms/components/shared/SiteContext";
import { useMenu } from "cms/services/SectionService";
import generateTemplatePropsFromContents from "cms/utils/templatePropsUtils";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import LogoHeader from "./LogoHeader";
import Navigation from "./Navigation";
import useTheme from "@mui/material/styles/useTheme";
import NavigationMobile from "./NavigationMobile";
import ZoomContext from "../ZoomContext";
import { styled } from "@mui/system";

const navigationbarStyled = {
  bgcolor: "primary.light",
  borderBottom: "1px solid ",
  borderColor: "#DEE1F2",
  position: "relative"
};

const navigationbarStyledMobile = {
  backgroundColor: "white",
  position: "fixed"
};

const HeaderBaseStyled = styled("header")(({ theme }) => ({
  padding: 8,
  display: "flex",
  justifyContent: "space-between",
  minHeight: "64px",
  alignItems: "center",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 51
}));

const Header = () => {
  const site = useContext(SiteContext);
  const { zoom } = useContext(ZoomContext);
  const { menu } = useMenu(site.id);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const menuContents = (menu && generateTemplatePropsFromContents(menu.contents)) || {};
  let menuItems = menuContents.menus || [];

  if (!Array.isArray(menuItems)) {
    menuItems = [menuItems];
  }
  const { settings: siteSettings } = site || {};
  const { logoHeader = null } = siteSettings || {};

  return (
    <HeaderBaseStyled sx={isMobile ? navigationbarStyledMobile : navigationbarStyled}>
      <Box sx={{ display: "flex", gap: "8px", p: 0, marginLeft: { lg: "-8px" } }}>
        <LogoHeader logo={logoHeader} />
      </Box>

      {isMobile ? <NavigationMobile menuItems={menuItems} logo={logoHeader} /> : <Navigation menuItems={menuItems} />}
    </HeaderBaseStyled>
  );
};

export default Header;
