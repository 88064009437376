var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useContext } from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Toolbar from "@mui/material/Toolbar";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { createQueryParams } from "cms/utils/urlUtils";
import Icon from "cms/components/front-office/contents/Icon";
import Autosuggest from "cms/components/front-office/contents/Autosuggest";
import Link from "cms/components/front-office/contents/Link";
import MessageContext from "cms/components/shared/MessageContext";
import SiteContext from "cms/components/shared/SiteContext";
import SearchService from "cms/services/SearchService";
var inputStyled = {
    bgcolor: "white",
    border: "1px solid",
    borderColor: "primary.main",
    px: 2,
    py: 0,
    height: "56px",
    fontSize: "1em",
    "& input": {
        height: "1.4rem"
    },
    "& button": {
        mr: -1.5,
        color: "primary.dark"
    }
};
var autoSuggestStyled = function (theme) { return ({
    container: {
        width: "100%",
        position: "relative",
        padding: 0
    },
    suggestionsContainer: {
        position: "absolute",
        left: 0,
        right: 0,
        backgroundColor: theme.palette.common.white,
        border: "1px solid " + theme.palette.primary.main,
        borderBottom: "none",
        borderTop: "none",
        zIndex: 100
    },
    suggestionsList: {
        listStyleType: "none",
        paddingLeft: 0,
        margin: 0
    }
}); };
var suggestionStyled = {
    borderBottom: "1px solid",
    borderColor: "primary.main",
    "&:hover, &[aria-selected=true]": {
        bgcolor: "secondary.light"
    }
};
var SearchBar = function (props) {
    var _a = props.setMenuMobileActive, setMenuMobileActive = _a === void 0 ? function () { return null; } : _a, _b = props.closeIcon, closeIcon = _b === void 0 ? null : _b;
    var _c = useLocation(), pathname = _c.pathname, search = _c.search;
    var history = useHistory();
    var _d = useState(""), value = _d[0], setValue = _d[1];
    var theme = useTheme();
    var displayError = useContext(MessageContext).displayError;
    var site = useContext(SiteContext);
    var goToSearchPage = function () {
        if (value) {
            setMenuMobileActive(false);
            // onSearch();
            history.push({
                pathname: "/rechercher",
                search: "?" + createQueryParams({ q: value }),
                state: { prevPath: pathname + search }
            });
        }
    };
    var handleIconClick = function () {
        goToSearchPage();
    };
    var handleKeyDown = function (e) {
        if (e.key === "Enter") {
            goToSearchPage();
        }
    };
    var getSuggestions = function (newValue) { return __awaiter(void 0, void 0, void 0, function () {
        var sugg, hits, results, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, SearchService.search({ query: newValue, from: 0, size: 5, siteId: site.id })];
                case 1:
                    sugg = _a.sent();
                    hits = (sugg || {}).hits;
                    results = (hits || {}).hits;
                    return [2 /*return*/, (results && Array.isArray(results) && results.map(function (res) { return res._source; })) || []];
                case 2:
                    e_1 = _a.sent();
                    displayError("Le service de recherche est actuellement indisponible. Veuillez nous excuser pour la gêne occasionnée.");
                    return [2 /*return*/, []];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getSuggestionValue = function (suggestion) { return suggestion.title; };
    var renderSuggestion = function (suggestion) {
        var breadcrumb = suggestion.breadcrumb.map(function (b) { return b.title; });
        if (breadcrumb && breadcrumb.length > 3) {
            breadcrumb = [breadcrumb[0], breadcrumb[1], "...", breadcrumb[breadcrumb.length - 1]];
        }
        var subtitle = breadcrumb.join(" / ");
        return (_jsx(Box, __assign({ sx: __assign({ px: 3, py: 1.5 }, suggestionStyled) }, { children: _jsxs(Link, __assign({ page: suggestion }, { children: [_jsx("b", { children: suggestion.title }, void 0), _jsx(Box, __assign({ sx: { fontSize: "0.76rem", mt: 0.5, fontWeight: 300 } }, { children: subtitle }), void 0)] }), void 0) }), void 0));
    };
    var onChange = function (event, _a) {
        var newValue = _a.newValue;
        setValue(newValue);
    };
    var onSuggestionSelected = function () {
        // onSearch();
        setValue("");
    };
    var renderInputComponent = function (inputProps) { return (_jsx(Input, __assign({ inputProps: {
            "aria-label": "Lancez la recherche en saisissant un texte"
        }, role: "combobox", "aria-expanded": "false", autoFocus: true, sx: inputStyled, disableUnderline: true, endAdornment: _jsxs(InputAdornment, __assign({ position: "end" }, { children: [_jsx(IconButton, __assign({ "aria-label": "Lancez la recherche", onClick: handleIconClick, size: "large" }, { children: _jsx(Icon, { icon: "search", title: "Rechercher" }, void 0) }), void 0), closeIcon] }), void 0), onKeyDown: handleKeyDown }, inputProps, { fullWidth: true }), void 0)); };
    var inputProps = {
        placeholder: "Lancez votre recherche",
        value: value,
        onChange: onChange,
        onKeyDown: handleKeyDown
    };
    // Accessibilité: remove un role="listbox" non pertinent
    if (typeof document !== "undefined") {
        var suggestContainer = document.getElementById("react-autowhatever-1");
        if (suggestContainer) {
            suggestContainer.removeAttribute("role");
        }
    }
    return (_jsx(Toolbar, __assign({ disableGutters: true, sx: { width: "100%", position: "relative", p: 0 } }, { children: _jsx(Autosuggest, { getSuggestions: getSuggestions, debounce: 300, getSuggestionValue: getSuggestionValue, renderSuggestion: renderSuggestion, renderInputComponent: renderInputComponent, onSuggestionSelected: onSuggestionSelected, inputProps: inputProps, classes: autoSuggestStyled(theme), noResult: _jsx(Box, __assign({ sx: __assign({ px: 3, py: 1.5 }, suggestionStyled) }, { children: _jsxs("b", { children: ["Aucun r\u00E9sultat pour la recherche : \u00AB ", value, " \u00BB"] }, void 0) }), void 0) }, void 0) }), void 0));
};
export default SearchBar;
