var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext } from "react";
import { useQuery } from "react-query";
import SiteService from "cms/services/SiteService";
import generateTemplatePropsFromContents from "cms/utils/templatePropsUtils";
var SiteContext = createContext({});
export default SiteContext;
export var SiteContextProvider = function (props) {
    var _a = props.site, site = _a === void 0 ? null : _a, _b = props.children, children = _b === void 0 ? null : _b;
    var data = useQuery("site", SiteService.get).data;
    // il est possible de passer un site props, cas du back office
    var currentSite = (typeof data === "object" && data.id && data) || site || {};
    if (currentSite && currentSite.contents) {
        var contentsProps = generateTemplatePropsFromContents(currentSite.contents);
        var settings = (contentsProps || {}).settings;
        currentSite.settings = settings;
    }
    return _jsx(SiteContext.Provider, __assign({ value: currentSite }, { children: children }), void 0);
};
