import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import Text from "cms/components/front-office/contents/Text";
import TimelineStep from "cms/components/front-office/contents/TimeLineStep";
import Timeline from "cms/components/front-office/contents/TimeLine";
var steps = new Array(3)
    .fill("")
    .map(function (p, i) { return (_jsx(TimelineStep, { id: i, title: _jsxs(Text, { children: ["`Titre $", i + 1, "`"] }, void 0), content: _jsx(Text, { children: "Zone de texte libre " + (i + 1) }, void 0) }, i)); });
var TimelinePreview = function () {
    return _jsx(Timeline, { steps: steps }, void 0);
};
export default TimelinePreview;
