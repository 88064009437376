var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Link from "cms/components/front-office/contents/Link";
import Box from "@mui/material/Box";
var LogoHeader = function (props) {
    return (_jsx(Link, __assign({ url: "/", sx: { height: "60px" }, title: "Retour vers l'accueil de M.I.A Seconde" }, { children: props.logo || _jsx(Box, { component: "img", alt: "logo", src: "/Logo-header.png", sx: { width: "100%", height: "100%" } }, void 0) }), void 0));
};
export default LogoHeader;
